/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { PinCode } from "baseui/pin-code";
// import {useStyletron} from 'baseui';
// import { FormControl } from "baseui/form-control";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useNavigate, useLocation } from 'react-router-dom';
// import axios from 'axios';
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { Analytics, Gtag } from '../compornents/Analytics';
import { BsChevronLeft } from "react-icons/bs";
import axios from 'axios';

export const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPage, setPage] = useState(5);
  const engine = new Styletron();
  const [value, setValue] = useState("");
  const [isName, setName] = useState("");
  const [isName2, setName2] = useState("");
  const [isFuri, setFuri] = useState("");
  const [isFuri2, setFuri2] = useState("");
  const [isZip, setZip] = useState(location.state != null ? location.state.postcode : "");
  const [isPref, setPref] = useState("福岡県");
  const [isAdd1, setAdd1] = useState("福岡市");
  const [isAdd2, setAdd2] = useState("");
  const [isAdd3, setAdd3] = useState("");
  const [isRoom, setRoom] = useState("");
  const [isAddNote, setAddNote] = useState("");
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const position = { lat: 0, lng: 0 };
  const [isCenter, setCenter] = useState(position);
  const [isCustomer_id, setCustomer_id] = useState("");
  const [smsError, setSmsError] = useState("");
  const [viewToArea, setViewToArea] = useState(false);

  // モーダル
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  // 戻る
  const back = () => {
    setPage(isPage-1);
  };

  // console.log(customer);
  // console.log(setCustomer(""));
  const options = {
    disableDefaultUI: true,
    // デフォルトUI（衛星写真オプションなど）をキャンセルします。
    zoomControl: false,
    clickableIcons: false
  };
  const geocode = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: isPref + isAdd1 + isAdd2 + isAdd3 }, (results, status) => {
      console.log(results[0].geometry.location.lng());
      console.log(status);
      if (status === 'OK') {
        setCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
      }else{
        // Geocodingが失敗した場合、現在の位置を取得する
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }, (error) => {
            console.error("Geolocation error:", error);
          });
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }
    });
  }

  const fetchAddress = async (zip) => {
    // console.log(zip);
    if (zip.length === 7) {
      try {
        const response = await axios.get(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`
        );
        const data = response.data;

        // console.log(response);
        if (data.results) {
          const result = data.results[0];
          console.log(result);
          setAdd1(`${result.address2}${result.address3}`);
          // const fullAddress = `${result.address1}${result.address2}${result.address3}`;
          // setAddress(fullAddress);
        } else {
          // setAddress("住所が見つかりません");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        // setAddress("エラーが発生しました");
      }
    }
  };


  const next = () => {
    if (isPage === 9) {
      setPage(0);
    } else {
      if (isPage === 5) {
        send_sms();
      } else if (isPage === 6) {
        check_sms();
      } else if (isPage === 7) {
        update_customer();
      } else if (isPage === 8) {
        update_location();
      } else {
        setPage(isPage + 1);
      }
    }
  }


  async function send_sms() {
    openModal();
    const request_data = {
      'key': 'yt~z7ESU6J~tLB7ppw(CLD8WddX9PNsr6DH',
      'tel': value,
    }
    // post
    const response_data = await Post('public', '/auth/send_sms.php', request_data);
    if (response_data.result === 1) {
      setPage(6);
      setCustomer_id(response_data.customer_id);
      // SMS送信成功
      Gtag('send_sms_success');
      Gtag('view_register_authcode');
    } else {
      console.log(response_data);
      // SMS送信失敗
      Gtag('send_sms_failed', {
        error_message: response_data.error_message,
      });
      // エラー
      toast(response_data.error_message);
    }
    setSmsError("");
    closeModal();
  }

  async function check_sms() {
    openModal();
    const request_data = {
      'key': 'yt~z7ESU6J~tLB7ppw(CLD8WddX9PNsr6DH',
      'customer_id': isCustomer_id,
      'sms_auth_code': values[0] + values[1] + values[2] + values[3] + values[4] + values[5],
    }
    // post
    const response_data = await Post('login', '/auth/check_sms.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      // SMS認証成功
      Gtag('authcode_success');
      if (response_data.is_new === 0) {
        navigate('/home');
      }

      setPage(7);
      Gtag('view_register_customer');
    } else {
      // SMS認証失敗
      Gtag('authcode_failed', {
        error_message: response_data.error_message,
      });
      setSmsError("error");
    }
    closeModal();
  }

  async function update_customer() {
    openModal();
    setViewToArea(false);
    const request_data = {
      'first_name': isName,
      'last_name': isName2,
      'first_furigana': isFuri,
      'last_furigana': isFuri2,
      'zip': isZip,
      'pref': isPref,
      'add1': isAdd1,
      'add2': isAdd2,
      'add3': isAdd3,
      'room': isRoom,
      'add_note': isAddNote,
    }
    // post
    const response_data = await Post('post', '/customer/update.php', request_data);
    if (response_data.result === 1) {
      geocode();
      // 顧客登録成功
      Gtag('register_customer_success');
      Gtag('view_register_location');
      setPage(8);
    } else {
      // エラー
      toast(response_data.error_message);
      if(response_data.error_code==='E003'){
        setViewToArea(true);
        // エリア対象外
        Gtag('out_of_service_area');
      }
      // 顧客登録失敗
      Gtag('register_customer_failed', {
        error_message: response_data.error_message,
      });
    }
    closeModal();
  }

  // エリア対象内になったら通知を受け取る
  function toArea() {
    // 対象エリア内
    Gtag('request_mail_within_service_area', { postcode: isZip });
    window.open('https://forms.gle/N2xDHLPpByedyF5p9', '_blank');
  };

  async function update_location() {
    openModal();
    setViewToArea(false);
    const request_data = {
      'lat': isCenter.lat,
      'lon': isCenter.lng,
    }
    // post
    const response_data = await Post('post', '/customer/update_location.php', request_data);
    if (response_data.result === 1) {
      // サインアップコンバージョン
      Gtag('conversion_event_signup');
      const request_data2 = {};
      const response_data2 = await Post('post', '/order/item.php', request_data2);
      if (response_data2.result === 1) {
        const items = response_data2.items;
        // 見積もり取得
        const request_data3 = {}
        const response_data3 = await Post('post', '/order/quote.php', request_data3);
        console.log(response_data3);
        if (response_data3.result === 1) {
          const quote = response_data3.quote;
          navigate("/confirm", { state: { item: items[0], quote: quote } });
        } else {
          // エラー
          // navigate("/home");
          toast(response_data3.error_message);
          if(response_data.error_code==='E021'){
            // 11秒後にホームへ
            setTimeout(() => {
              navigate("/home");
            }, 11000);
          }
          if(response_data3.error_code==='E003'){
            setViewToArea(true);
          }
        }
      } else {
        // エラー
        toast(response_data2.error_message);
      }
    } else {
      Gtag('register_location_failed', {
        error_message: response_data.error_message,
      });
      toast(response_data.error_message);
      if(response_data.error_code==='E003'){
        setViewToArea(true);
      }
    }
    setSmsError("");
    closeModal();
  }


  useEffect(() => {
    Gtag('view_register_send_sms');
  }, []);

  if (isPage === 8) {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Analytics title="ピンの位置の調整" />
            <div className="Register m-auto">
              <div className="App">
                <div className="toolbar mb-3">
                  <div className="toolbar_left div_btn" onClick={back} >
                    <BsChevronLeft />
                  </div>
                  <div className="toolbar_center">
                    ピンの位置を調整<span className="text-danger">(重要)</span>
                  </div>
                  <div className="toolbar_right">
                  </div>
                </div>
                <div className="Add-comment">{isAdd2}</div>
                <div className="App-map">
                  <Map
                    center={isCenter}
                    zoom={18}
                    onCenterChanged={e => {
                      // console.log(e.detail.center.lat);
                      console.log(e.detail.center.lng);
                      setCenter({ lat: e.detail.center.lat, lng: e.detail.center.lng });
                    }}
                    options={options}
                  >
                    <Marker position={isCenter} />
                  </Map>
                </div>
                <div className="Map-comment">{"Uber配達員が速やかにお伺いできるよう、ピンでお届け先の正しい位置を示してください"}</div>

                {viewToArea===true && (
                  <div className="">
                    <div className="text-danger m-3">
                      申し訳ございません。ご利用エリア外の住所です。利用可能になったら通知を受け取ることができます。以下のボタンをタッチしてください。
                    </div>
                    <div className="m-3">
                      <button className="btn btn-sm btn-oval btn-outline-secondary w-100" onClick={toArea}>利用可能になったら通知を受け取る &gt;</button>
                    </div>
                  </div>
                )}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}>
                  {!values.includes('') ? <button className="button-next" onClick={next}>{"住所を保存"}</button> : <button className="button-next-g">{"住所を保存"}</button>}
                </div>
              </div>
            </div>
            <Modal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </Modal>
          </APIProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }
  if (isPage === 7) {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Analytics title="個人情報入力" />
            <div className="Register m-auto">
              <div className="App-inline mb-5 pb-5">
                <div className="App-subtitle">{"個人情報入力"}</div>
                <div className="Input-view">
                  <div className="Input-title">{"お名前（漢字）"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isName}
                        onChange={e => setName(e.target.value)}
                        placeholder="例）山田"
                        clearOnEscape
                      />
                      <Input
                        value={isName2}
                        onChange={e => setName2(e.target.value)}
                        placeholder="花子"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"お名前（ふりがな）"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isFuri}
                        onChange={e => setFuri(e.target.value)}
                        placeholder="例）やまだ"
                        clearOnEscape
                      />
                      <Input
                        value={isFuri2}
                        onChange={e => setFuri2(e.target.value)}
                        placeholder="はなこ"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"郵便番号"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isZip}
                        onChange={e => {
                          fetchAddress(e.target.value);
                          setZip(e.target.value);}}
                        placeholder="例:8001234"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"都道府県"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isPref}
                        onChange={e => setPref(e.target.value)}
                        placeholder="例:東京都"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"市区町村"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isAdd1}
                        onChange={e => setAdd1(e.target.value)}
                        placeholder="例:港区六本木"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"丁目または番地"} <span className="Input-require">{"*"}</span>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isAdd2}
                        onChange={e => setAdd2(e.target.value)}
                        placeholder="例:1-1-1"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"建物名"}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isAdd3}
                        onChange={e => setAdd3(e.target.value)}
                        placeholder="例:ランドリーマンション"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"部屋番号"}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Input
                        value={isRoom}
                        onChange={e => setRoom(e.target.value)}
                        placeholder="例:101"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                <div className="Input-view">
                  <div className="Input-title">{"住所の詳細"}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                      paddingTop: '10px',
                    }}>
                      <Textarea
                        value={isAddNote}
                        onChange={e => setAddNote(e.target.value)}
                        placeholder="例:一戸建、表札あり、建物の外観"
                        clearOnEscape
                      />
                    </div>
                  </div>
                </div>
                {viewToArea===true && (
                  <div className="">
                    <div className="text-danger m-3">
                      申し訳ございません。ご利用エリア外の住所です。利用可能になったら通知を受け取ることができます。以下のボタンをタッチしてください。
                    </div>
                    <div className="m-3">
                      <button className="btn btn-sm btn-oval btn-outline-secondary w-100" onClick={toArea}>利用可能になったら通知を受け取る &gt;</button>
                    </div>
                  </div>
                )}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}>
                  {!values.includes('') ? <button className="button-next" onClick={next}>{"次へ"}</button> : <button className="button-next-g">{"次へ"}</button>}
                </div>
              </div>
            </div>
            <Modal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </Modal>
          </APIProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }
  if (isPage === 6) {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
          <Analytics title="認証コード入力" />
          <div className="Register m-auto">
            <div className="App-tel">
              <div className="tel-title">{"届いた認証コードを\n入力してください"}</div>
              <div className="App-comment">{value}{"に届いた6桁の\n認証コードを入力してください"}</div>
              <div className="App-telno">
                <PinCode
                  values={values}
                  onChange={({ values }) => setValues(values)}
                  autoFocus
                  error={smsError}
                />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
              }}>
                {!values.includes('') ? <button className="button-next" onClick={next}>{"認証する"}</button> : <button className="button-next-g">{"認証する"}</button>}
              </div>
            </div>
          </div>

          <Modal>
            <div className="modal rounded text-center pb-4 pl-4 pr-4">
              <button onClick={closeModal} className="hide">閉じる</button>
              <div >
                <TailSpin stroke="#000" />
              </div>
              <div className="">
                <div className="">
                  処理中です
                </div>
              </div>
            </div>
          </Modal>
        </BaseProvider>
      </StyletronProvider>
    );
  }
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="携帯電話番号入力" />
        <div className="Register m-auto">
          <div className="App-tel">
            <div className="tel-title">{"携帯電話番号を\n入力してください"}</div>
            <div className="App-comment">{"アカウントの認証・保護に使用します"}</div>
            <div className="App-telno">
              <input type="tel" value={value} onChange={e => setValue(e.target.value)} placeholder="例）09012345678" className="form-control" />
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}>
              {value.length === 11 ? <button className="button-next" onClick={next}>{"次へ"}</button> : <button className="button-next-g">{"次へ"}</button>}
            </div>
          </div>
        </div>
        <Modal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </Modal>
      </BaseProvider>
    </StyletronProvider>
  );
}
