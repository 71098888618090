/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics,Gtag,FacebookPixel } from '../compornents/Analytics';
import PayjpCheckout from 'react-payjp-checkout';

export const Credit = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const [DeleteCreditModal, openDeleteCreditModal, closeDeleteCreditModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  // クレジットカード登録
  async function createCredit() {
    // openLoadingModal();
    console.log('createCredit');
    // 見積もり取得
    let payjp_token = document.getElementsByName('create_order_payjp_token')[0].value;
    const request_data = { payjp_token: payjp_token };
    const response_data = await Post('post', '/customer/create_credit.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      Gtag('create_credit_success');
      FacebookPixel('AddPaymentInfo');
      customer.card_type = response_data.card_type;
      customer.expYear = response_data.expYear;
      customer.expMonth = response_data.expMonth;
      customer.last4 = response_data.last4;
      setCustomer(customer);
      toast("カード情報が保存されました");
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('create_credit_failed', {
        error_message: response_data.error_message,
      });
    }
    // closeLoadingModal();
  };

  const fail = () => {
    console.log('fail');
    toast('クレジットカード情報が正しくありません');
    Gtag('credit_failed');
  }

  var payjp_public_key = '';
  if (process.env.REACT_APP_IS_DEV === 'true') {
    payjp_public_key = "pk_test_88ed3508dd9a23c2c538aa38";
  } else {
    payjp_public_key = "pk_live_fa0603bf01da67561383016d";
  }
  const payjpCheckoutProps = {
    // dataKey: 'pk_live_fa0603bf01da67561383016d',
    dataKey: payjp_public_key,
    dataText: '新規クレジットカード登録',
    dataPartial: 'true',
    dataSubmitText: '保存する',
    dataTokenName: 'create_order_payjp_token',
    dataPayjpThreeDSecure: 'true',
    dataPayjpThreeDSecureWorkflow: 'subwindow',
    dataPayjpExtraAttributePhone: 'true',
    onCreatedHandler: createCredit,
    onFailedHandler: fail,
  }
  // クレジットカード削除
  async function deleteCredit() {
    closeDeleteCreditModal();
    openLoadingModal();
    // console.log('update');
    // 見積もり取得
    const request_data = {};
    const response_data = await Post('post', '/customer/delete_credit.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      Gtag('delete_credit_success');
      customer.card_type = null;
      customer.expYear = null;
      customer.expMonth = null;
      customer.last4 = null;
      setCustomer(customer);
      toast("カード情報が削除されました");
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('delete_credit_failed');
    }
    closeLoadingModal();
  };
  useEffect(() => {
    let unmounted = false;
    // 顧客情報取得
    (async () => {
      openLoadingModal();
      const request_data = {}
      // post
      const response_data = await Post('post', '/customer/index.php', request_data);
      // 結果
      console.log(response_data);
      if (response_data.result === 1) {
        const customer = response_data.customer;
        if (!unmounted) {
          setCustomer(customer);
        }
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    })();

    //クリーンアップ関数を返す
    return () => { unmounted = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Credit">
        <Analytics title="クレジットカードの登録" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            クレジットカードの登録
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        {customer === null ? (
          <div>
          </div>
        ): customer.card_type === null ?(
          <div className="m-4">
            <div className="mb-2">以下のカード会社に対応しております。</div>
            <div className="mb-3">
              <img src="./img/credit_brands.png" alt="step1" className="ItemImage" />
            </div>
            <div className="mb-2 text-center">
              <PayjpCheckout {...payjpCheckoutProps} />
            </div>
          </div>
        )  : (
          <div>
            <div className="m-4">
              <div className="h5 mb-2">
                カードブランド
              </div>
              <div className="mb-3">
                {customer.card_type === 'American Express' ? (
                  <img src="./img/ic/American Express.png" alt="CreditCard" className="creditIcon" />
                ) : customer.card_type === 'Diners Club' ? (
                  <img src="./img/ic/Diners Club.png" alt="CreditCard" className="creditIcon" />
                ) : customer.card_type === 'Discover' ? (
                  <img src="./img/ic/Discover.png" alt="CreditCard" className="creditIcon" />
                ) : customer.card_type === 'JCB' ? (
                  <img src="./img/ic/JCB.png" alt="CreditCard" className="creditIcon" />
                ) : customer.card_type === 'MasterCard' ? (
                  <img src="./img/ic/MasterCard.png" alt="CreditCard" className="creditIcon" />
                ) : customer.card_type === 'Visa' && (
                  <img src="./img/ic/Visa.png" alt="CreditCard" className="creditIcon" />
                )}
              </div>
              <div className="h5 mb-2">
                カード番号
              </div>
              <div className="mb-3">
                **** **** **** {customer.last4}
              </div>
              <div className="h5 mb-2">
                有効期限
              </div>
              <div className="mb-5">
                {customer.expMonth} / {customer.expYear}
              </div>
              <div className="mb-3 text-center">
                <Link className="text-danger link_deco_none" onClick={openDeleteCreditModal}>カード情報を削除する</Link>
              </div>
            </div>
          </div>
        )}
        <DeleteCreditModal>
          <div className="modal rounded p-4">
            <div className="">
              <div className="">
                <div className="h4 text-center mb-3 mt-2">カード情報を削除します</div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  この操作によりお支払い方法からこのカードの情報が削除されます
                </div>
              </div>
              <div>
                <div className="row mb-2">
                  <div className="col-6">
                    <button className="btn btn-oval btn-sm btn-gray-dark form-control" onClick={closeDeleteCreditModal}>キャンセル</button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-oval btn-sm btn-danger  form-control" onClick={deleteCredit}>削除する</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DeleteCreditModal>
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="account" />
      </div>
    </div>
  );


}
