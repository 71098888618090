/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { RiMapPin2Line } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { toast } from 'react-toastify';
import { Analytics } from '../compornents/Analytics';
import {Badge,PLACEMENT, SHAPE, COLOR } from "baseui/badge";
import { LightTheme, BaseProvider } from 'baseui';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';

const engine = new Styletron();
export const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  // 注文選択
  const selectOrder = (order) => {
    // console.log(order);
    navigate("/order/" + order.id,{state:{order:order}});
  };
  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  useEffect(() => {
    // 注文情報取得
    async function getOrders() {
      openLoadingModal();
      const request_data = {}
      // post
      const response_data = await Post('post', '/order/index.php', request_data);
      // 結果
      console.log(response_data);
      if (response_data.result === 1) {
        setOrders(response_data.orders);
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    }
    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(orders.length);
  const listOrders = orders.length > 0 ? orders.map(order =>
    <Badge content={order.new_comment_qty}
      horizontalOffset="0"
      placement={PLACEMENT.topRightEdge}
      shape={SHAPE.pill}
      color={COLOR.negative}
      hidden={order.new_comment_qty === 0}
      key={order.id}>
      <div className="order mb-3 div_btn" onClick={() => selectOrder(order)}>
        <div className="order_content">
          <div className="order_header mb-2">
            <div className={Number(order.otype) === 8 || Number(order.otype) === 11 ? "order_status" : "order_status text-blue"} >
              {order.status_name}
            </div>
            <div className="order_date">
              ・{order.order_date}
            </div>
          </div>

          <div className="order_item mb-2">
            {order.item_name}
          </div>
          <div className="order_user_info mb-3">
            <div className="order_pin mr-1">
              <RiMapPin2Line />
            </div>
            <div className="order_address">
              { order.pref + order.add1 + order.add2 }<br/>
              { order.add3 }{ order.room }
            </div>
          </div>
        </div>
        <div className="order_arrow">
          <IoIosArrowForward />
        </div>
      </div>
    </Badge>
  ) : (<div>ご注文はありません</div>);
  return (

    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <div className="App">
          <div className="Orders">
            <Analytics title="以前の注文" />
            <div className="mt-3 mb-5">
              <div className="h3 mt-4 mb-4 text-left">
                以前の注文
              </div>
              <div className="">
                {listOrders}
              </div>
            </div>
            <LoadingModal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeLoadingModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </LoadingModal>
            <Tabbar menu="orders" />
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
