/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics } from '../compornents/Analytics';

export const Profile = () => {
  const navigate = useNavigate();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_furigana, setFirstFurigana] = useState("");
  const [last_furigana, setLastFurigana] = useState("");
  const [zip, setZip] = useState("");
  const [pref, setPref] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [add3, setAdd3] = useState("");
  const [room, setRoom] = useState("");
  const [addNote, setAddNote] = useState("");
  const [viewSubmitBtn, setViewSubmitBtn] = useState(true);
  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const handleBlur = (e) => {
    if (first_name !== '' &&
      last_name !== '' &&
      first_furigana !== '' &&
      last_furigana !== '' &&
      zip !== '' &&
      pref !== '' &&
      add1 !== '' &&
      add2 !== '') {
      setViewSubmitBtn(true);
    } else {
      setViewSubmitBtn(false);
    }
  }
  // 顧客更新
  async function updateCustomer() {
    openLoadingModal();
    console.log('update');
    // 見積もり取得
    const request_data = {
      first_name:first_name,
      last_name:last_name,
      first_furigana:first_furigana,
      last_furigana:last_furigana,
      zip:zip,
      pref:pref,
      add1:add1,
      add2: add2,
      add3: add3,
      room: room,
      add_note: addNote,
     };
    const response_data = await Post('post', '/customer/update.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      // navigate("/account");
      navigate("/location2", { state: { customer: response_data.customer } });
    } else {
      // エラー
      toast(response_data.error_message);
    }
    closeLoadingModal();
  };
  useEffect(() => {
    let unmounted = false;
    // 顧客情報取得
    (async () => {
      openLoadingModal();
      const request_data = {}
      // post
      const response_data = await Post('post', '/customer/index.php', request_data);
      // 結果
      console.log(response_data);
      if (response_data.result === 1) {

        const first_name = response_data.customer.first_name;
        const last_name = response_data.customer.last_name;
        const first_furigana = response_data.customer.first_furigana;
        const last_furigana = response_data.customer.last_furigana;
        const zip = response_data.customer.zip;
        const pref = response_data.customer.pref;
        const add1 = response_data.customer.add1;
        const add2 = response_data.customer.add2;
        const add3 = response_data.customer.add3;
        const room = response_data.customer.room;
        const add_note = response_data.customer.add_note;
        if (!unmounted){
          setFirstName(first_name);
          setLastName(last_name);
          setFirstFurigana(first_furigana);
          setLastFurigana(last_furigana);
          setZip(zip);
          setPref(pref);
          setAdd1(add1);
          setAdd2(add2);
          setAdd3(add3);
          setRoom(room);
          setAddNote(add_note);
          if (first_name !== '' &&
            last_name !== '' &&
            first_furigana !== '' &&
            last_furigana !== '' &&
            zip !== '' &&
            pref !== '' &&
            add1 !== '' &&
            add2 !== '') {
            setViewSubmitBtn(true);
          } else {
            setViewSubmitBtn(false);
          }
        }
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    })();

    //クリーンアップ関数を返す
    return () => { unmounted = true; };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Profile">
        <Analytics title="個人情報" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            個人情報
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-4">
            <div className="mb-3">
              <div className="mb-2">
                お名前（漢字）
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-6">
                  <input type="text" className="form-control" placeholder="例）山田" value={first_name}
                    onChange={e => setFirstName(e.target.value)} onBlur={handleBlur} />
                </div>
                <div className="col-6">
                  <input type="text" className="form-control" placeholder="花子" value={last_name} onChange={e => setLastName(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                お名前（ふりがな）
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-6">
                  <input type="text" className="form-control" placeholder="例）やまだ" value={first_furigana} onChange={e => setFirstFurigana(e.target.value)} onBlur={handleBlur} />
                </div>
                <div className="col-6">
                  <input type="text" className="form-control" placeholder="はなこ" value={last_furigana} onChange={e => setLastFurigana(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                郵便番号
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）１２３４５６７" value={zip} onChange={e => setZip(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                都道府県
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）福岡県" value={pref} onChange={e => setPref(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                市区町村
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）福岡市" value={add1} onChange={e => setAdd1(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                丁目または番地
                <span className="text-danger">＊</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）中央区天神1-1" value={add2} onChange={e => setAdd2(e.target.value)} onBlur={handleBlur} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                建物名
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）ランドリーマンション" value={add3} onChange={e => setAdd3(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                部屋番号
              </div>
              <div className="row">
                <div className="col-12">
                  <input type="text" className="form-control" placeholder="例）１０１" value={room} onChange={e => setRoom(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                住所の詳細
              </div>
              <div className="row">
                <div className="col-12">
                  <textarea type="text" className="form-control" placeholder="例）一戸建、表札あり、建物の外観" value={addNote} onChange={e => setAddNote(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          {viewSubmitBtn === true ? (
            <div className="mb-2">
              <button className="btn btn-oval btn-yellow form-control" onClick={() => updateCustomer()}>変更する</button>
            </div>
          ) : (
            <div className="mb-2">
              <button className="btn btn-oval btn-gray-dark form-control">変更する</button>
            </div>
          )}
        </div>
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="account" />
      </div>

    </div>
  );
}
