/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { toast } from 'react-toastify';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons'
import { Analytics,Gtag } from '../compornents/Analytics';

export const Location = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const item = location.state.item;
  const quote = location.state.quote;
  const customer = location.state.customer;
  const position = { lat: 0, lng: 0 };
  const [isCenter, setCenter] = useState(position);

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const options = {
    disableDefaultUI: true,
    // デフォルトUI（衛星写真オプションなど）をキャンセルします。
    zoomControl: false,
    clickableIcons: false
  };
  // 顧客更新
  async function updateLocation() {
    openLoadingModal();
    console.log('update');
    // 見積もり取得
    const request_data = {
      lat : isCenter.lat,
      lon : isCenter.lng,
     };
    const response_data = await Post('post', '/customer/update_location.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      Gtag('update_location_success');
      navigate("/confirm",{state:{quote:quote,item:item}});
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('update_location_failed', {
        error_message: response_data.error_message
      });
    }
    closeLoadingModal();
  };
  useEffect(() => {
    const geocode = () => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: customer.pref + customer.add1 + customer.add2 + customer.add3 }, (results, status) => {
        console.log(results[0].geometry.location.lng());
        if (status === 'OK') {
          setCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
        }else{
          // Geocodingが失敗した場合、現在の位置を取得する
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            }, (error) => {
              console.error("Geolocation error:", error);
            });
          } else {
            console.error("Geolocation is not supported by this browser.");
          }
        }
      });
    }
    geocode();
  }, [customer.pref, customer.add1, customer.add2, customer.add3]);
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div className="App">
        <Analytics title="ピンの位置を調整(注文)" />
        <div className="Location">
          <div className="toolbar">
            <div className="toolbar_left div_btn" onClick={back} >
              <BsChevronLeft />
            </div>
            <div className="toolbar_center">
              ピンの位置を調整<span className="text-danger">(重要)</span>
            </div>
            <div className="toolbar_right">
            </div>
          </div>
          <div className="">
            <div className="mb-4 Register">

              <div className="App-map">
                <Map
                  center={isCenter}
                  zoom={18}
                  onCenterChanged={e => {
                    // console.log(e.detail.center.lat);
                    console.log(e.detail.center.lng);
                    setCenter({ lat: e.detail.center.lat, lng: e.detail.center.lng });
                  }}
                  options={options}
                >
                  <Marker position={isCenter} />
                </Map>
              </div>
              <div className="Map-comment mb-3">{"Uber配達員が速やかにお伺いできるよう、ピンでお届け先の正しい位置を示してください"}</div>

              <div className="m-3">
                <button className="btn btn-oval btn-yellow form-control" onClick={() => updateLocation()}>変更を保存</button>
              </div>
            </div>
          </div>
          <LoadingModal>
            <div className="modal rounded text-center pb-4 pl-4 pr-4">
              <button onClick={closeLoadingModal} className="hide">閉じる</button>
              <div >
                <TailSpin stroke="#000" />
              </div>
              <div className="">
                <div className="">
                  処理中です
                </div>
              </div>
            </div>
          </LoadingModal>
          <Tabbar menu="account" />
        </div>
      </div>
    </APIProvider>
  );

}
