import { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useModal } from 'react-hooks-use-modal';
import { Tabbar } from "../compornents/Tabbar";
import { Pagination } from 'swiper/modules';
import { Post } from '../compornents/Post';
import { ItemMenu } from '../compornents/ItemMenu';
import { TailSpin } from 'react-loading-icons'
import { toast } from 'react-toastify';
// import { useSelector, useDispatch } from "react-redux";
import { Analytics,Gtag } from '../compornents/Analytics';
import { IoMdClose } from "react-icons/io";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'swiper/css';
import 'swiper/css/pagination'; // スタイルをインポート


// 地図の中心位置とズームレベルを更新するためのカスタムフック
const UpdateMapView = ({ position, destination }) => {
  const map = useMap();
  const bounds = useMemo(() => L.latLngBounds([position, destination]), [position, destination]);

  useEffect(() => {
    map.fitBounds(bounds);
  }, [bounds, map]);

  return null;
};

function Home() {
  const navigate = useNavigate();
  // カスタムアイコンを定義する
  const carIcon = L.icon({
    iconUrl: 'img/car-solid.svg',
    iconSize: [25, 41], // アイコンのサイズ
    iconAnchor: [12, 21], // アイコンのアンカー（位置）
    popupAnchor: [1, -24], // ポップアップのアンカー（位置）
  });

  const destinationIcon = L.icon({
    iconUrl: 'img/house-solid.svg',
    iconSize: [25, 41], // アイコンのサイズ
    iconAnchor: [12, 21], // アイコンのアンカー（位置）
    popupAnchor: [1, -24], // ポップアップのアンカー（位置）
  });

  // 初期化
  const location = useLocation();
  const [activeOrder, setActiveOrder] = useState([]);
  const [inBusinessHours, setInBusinessHours] = useState(null);
  const [position, setPosition] = useState(null);
  const [destination, setDropoffLocation] = useState(null);
  const [smsText, setSmsText] = useState(null);
  // モーダル
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const [DeliveryBagModal, openDeliveryBagModal, closeDeliveryBagModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const [DeliveryCustomerPickupModal, openDeliveryCustomerPickupModal, closeDeliveryCustomerPickupModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const [DeliveryModal, openDeliveryModal, closeDeliveryModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const swiperRef = useRef(null);

  const fetchLocation = async (setPosition, order_id) => {
    try {
      const request_data = { order_id: order_id }
      const response = await Post('post', '/order/location.php', request_data);
      console.log(response);
      if (response.order_id_invalid === 0) {
        setActiveOrder(response.order);
        if (response.delivery_not_found===0){
          if (response.webhook_not_found === 0) {
            console.log([response.location_lat, response.location_lng]);
            setPosition([response.location_lat, response.location_lng]);
            setDropoffLocation([response.dropoff_location_lat, response.dropoff_location_lng]);
            setSmsText(response.sms_txt);
          }else{
            setPosition(null);
          }
        } else {
          setPosition(null);
        }
        console.log(Number(response.order.otype));
        if (Number(response.order.otype)!==1 && Number(response.order.otype)!==2 && Number(response.order.otype)!==7){
          console.log('clearInterval');
          clearInterval(intervalRef.current);
          // setIntervalId(null);
        }
        if (Number(response.order.item_id) === -5 && Number(response.order.otype) ===8){
          console.log(Number(response.order.item_id) === -5);
          console.log(Number(response.order.otype) === 8);
          navigate("/order_step/step0");
        }
      } else {
        console.log('clearInterval2');
        console.log(activeOrder);
        setActiveOrder([]);
        setPosition(null);
      }
    } catch (error) {
      console.error('位置情報の取得に失敗しました', error);
    }
  };
  const intervalRef = useRef(null);
  useEffect(() => {
    let unmounted = false;
    // 注文情報取得
    (async () => {
      openLoadingModal();
      const request_data = {};
      const response_data = await Post('post', '/order/active.php', request_data);
      console.log(response_data);
      if (response_data.result === 1) {
        const activeOrder = response_data.active_order;
        const inBusinessHours = response_data.in_business_hours;
        if(!unmounted){
          console.log('unmounted');
          setActiveOrder(activeOrder);
          setInBusinessHours(inBusinessHours);
          fetchLocation(setPosition, activeOrder.id);
          console.log('unmounted2');
          if (Number(activeOrder.otype) === 1 || Number(activeOrder.otype) === 7) {
            console.log('unmounted3');
            intervalRef.current = setInterval(() => {
              console.log('interval');
              fetchLocation(setPosition, activeOrder.id);
            }, 20000);
          }
        }
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    })();

    if (location.state != null) {
      if (location.state.is_first_order === 1) {
        if (!unmounted) openModal();
      }
    }

    Gtag('view_home');
    //クリーンアップ関数を返す

    return () => { unmounted = true; clearInterval(intervalRef.current); };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // トラッキングURL
  const openTrackingUrl = () => {
    Gtag('open_tracking_url');
    window.open(activeOrder.tracking_url, '_blank');
  };
  // 営業時間外
  function outBusinessHours(){
    console.log('outBusinessHours');
    toast('受取り可能時間外です。月〜土（日曜のみ定休）8:30-20:00');
  }

  // 配達を依頼する
  async function requestDeliveryBag() {
    Gtag('request_redelivery_bag', {
      order_id: activeOrder.id,
    });
    openLoadingModal();
    const request_data = { order_id: activeOrder.id, type:'delivery_bag'};
    const response_data = await Post('post', '/order/request_delivery.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      setActiveOrder(response_data.order);
      // window.open(response_data.order.tracking_url, '_blank');
    } else {
      // エラー
      toast(response_data.error_message);
    }
    closeDeliveryBagModal();
    closeLoadingModal();
  }

  // Cpickupの配達を依頼する
  async function requestDeliveryCustomerPickup() {
    Gtag('request_redelivery_bag', {
      order_id: activeOrder.id,
    });
    openLoadingModal();
    const request_data = { order_id: activeOrder.id, type:'customer_pickup'};
    const response_data = await Post('post', '/order/request_delivery.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      setActiveOrder(response_data.order);
      // window.open(response_data.order.tracking_url, '_blank');
    } else {
      // エラー
      toast(response_data.error_message);
    }
    closeDeliveryCustomerPickupModal();
    closeLoadingModal();
  }
  // 今すぐ受け取る
  async function requestDelivery() {
    Gtag('request_delivery', {
      order_id: activeOrder.id,
    });
    openLoadingModal();
    const request_data = {order_id:activeOrder.id,type:'company_pickup'};
    const response_data = await Post('post', '/order/request_delivery.php', request_data);
    console.log(response_data);
    closeLoadingModal();
    closeDeliveryModal();
    // toast('test');
    if (response_data.result === 1) {
      setActiveOrder(response_data.order);
      intervalRef.current = setInterval(() => {
        console.log('interval');
        fetchLocation(setPosition, activeOrder.id);
      }, 20000);
      window.open(response_data.order.tracking_url, '_blank');
    } else {
      // エラー
      toast(response_data.error_message);
    }
  }

  return (
    <div className="App">
      <div className="Home">
        <Analytics title="ホーム" />
        <div className="mb-5">

          {activeOrder === null ? (
            <div></div>
          ):activeOrder.length === 0 ? (
            <div className="home_contents">
              <ItemMenu />
            </div>
          ) : (
            <div>
              {Number(activeOrder.otype) === 1 ? (
                <div>
                  {Number(activeOrder.delivery_type) === -1 ? (
                    <div className="mb-3">
                      <div className="mb-3 home_header">
                        <div>
                          <div className="text-left">専用バッグをご自宅にお届けします。</div>
                          <div className="text-left">暗証番号をUber配達員にお伝えください</div>
                          <div className="h5">暗証番号 <strong>{activeOrder.pincode}</strong></div>
                        </div>
                      </div>
                    </div>
                  ) : activeOrder.delivery_type === null ? (
                    <div className="mb-3">
                      <div className="mb-3 home_header">
                        <div className="text-left">申し訳ございません</div>
                        <div className="text-left">配達がキャンセルされました</div>
                        <div className="h5">「配達を依頼する」ボタンをタッチして再度配達を依頼してください。</div>
                      </div>
                    </div>
                  ) : Number(activeOrder.delivery_type) === 0 && (
                    <div className="mb-3">
                      <div className="mb-3 home_header">
                        {activeOrder.delivery_status === 'pending' ? (
                          <div>
                            <div className="text-left">ご注文ありがとうございます！</div>
                            <div className="h5">集荷のためのUber配達員を募集中です。</div>
                          </div>
                        ) : activeOrder.delivery_status === 'pickup' && (
                          <div className="h5">Uber配達員が集荷に向かっています。{smsText}</div>
                        )}
                      </div>
                      {position !== null && (
                        <MapContainer center={[(position[0] + destination[0]) / 2, (position[1] + destination[1]) / 2]} zoom={13} style={{ height: "50vh", width: "100%" }}>
                          <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          />
                          <Marker position={position} icon={carIcon}>
                            <Popup>
                              移動する車
                            </Popup>
                          </Marker>
                          <Marker position={destination} icon={destinationIcon}>
                            <Popup>
                              目的地
                            </Popup>
                          </Marker>

                          <UpdateMapView position={position} destination={destination} />
                        </MapContainer>
                      )}
                    </div>

                  )}
                </div>
              ) : Number(activeOrder.otype) === 2 ? (
                <div>
                  <div className="mr-4 ml-4 mt-4 mb-2">
                    <div className="h5">Uber配達員が、クリーニング店に配送中です..</div>
                  </div>
                </div>
              ) : Number(activeOrder.otype) >= 3 && Number(activeOrder.otype) <= 5 ? (
                <div>
                  <div className="mr-4 ml-4 mt-4 mb-2">
                    <div className="text-left">クリーニング完了までお待ちください</div>
                    <div className="h5">クリーニング中です..</div>
                  </div>
                </div>
              ) : Number(activeOrder.otype) === 6 ? (
                <div>
                  <div className="mr-4 ml-4 mt-4 mb-2">
                    <div className="text-left">受け取りをお願いします</div>
                    <div className="h5">クリーニングが完了しました</div>
                  </div>
                </div>
              ) : Number(activeOrder.otype) === 7 && (
                <div>
                  <div className="mb-3 home_header">
                    <div className="text-left">暗証番号をUber配達員にお伝えください</div>
                    <div className="h5">暗証番号 <strong>{activeOrder.pincode}</strong></div>
                  </div>
                  <div className="mr-4 ml-4 mt-4 mb-2">
                    <div className="text-left">受け取りをお願いします</div>
                    <div className="h5">Uber配達員が向かっています..</div>
                  </div>
                </div>
              )}
              {activeOrder.item_id === '-5' ? (
                <div>
                  {/* 初回ステップ */}
                  <div className="">
                    {Number(activeOrder.otype) === 1 && Number(activeOrder.delivery_type) === 0 && activeOrder.delivery_type!=null && (
                      <div className="h5 m-1 text-danger text-center">集荷のUber配達員が到着するまでに<br/>STEP02とSTEP03をご確認ください↓</div>
                    )}
                    <div className="">
                      <Swiper
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper">
                        <SwiperSlide>
                          <div className="swiper_div2 rounded-lg">
                            <div className="">
                              <img src="./img/step01-01.png" alt="step1" className="ItemImage" />
                            </div>
                            <div className="p-3">
                              <div className="h4 mb-3 text-center">
                                専用バッグを受け取る
                              </div>
                              <div className="mb-3">
                                Uber配達員がご自宅に専用バッグをお届けします。<br />
                                暗証番号をUber配達員に伝えてください。
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* ２回目以降ステップ */}
                  {Number(activeOrder.otype)===1 && (
                    <div className="h5 m-1 text-center"><span className="text-danger">集荷のUber配達員が到着するまでに<br />STEP01</span>と<span className="text-danger">STEP02</span>をご確認ください↓</div>
                  )}
                  <div className="">
                    <Swiper
                      slidesPerView={'auto'}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper">
                      <SwiperSlide>
                        <div className={Number(activeOrder.otype) <= 2 ? "swiper_div rounded-lg" : "swiper_div rounded-lg swiper_success"}>
                          <div className="">
                            <img src="./img/step2-01.png" alt="step1" className="ItemImage" />
                          </div>
                          <div className="p-3">
                            <div className="h4 mb-3 text-center">

                              選択した点数の衣類を<br />
                              専用バッグに入れる
                            </div>
                            <div className="text-danger">
                              <small>
                                ※専用バッグに入り切る衣類のみ受付可能です。<br />
                                ※専用バッグは初回利用時にお渡ししています。<br />
                                ※専用バッグに入れて、しっかりと密封してください。<br />
                                ※ご依頼品を専用バッグに入れていない場合、かつ密封されていない場合、サービスをお受けできない場合があります。
                              </small>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className={Number(activeOrder.otype) <= 2 ? "swiper_div rounded-lg" : "swiper_div rounded-lg swiper_success"}>
                          <div className="">
                            <img src="./img/step2-02.png" alt="step2" className="ItemImage" />
                          </div>
                          <div className="p-3">
                            <div className="h4 mb-3 text-center">
                              Uber配達員に渡す
                            </div>
                            <div className="mb-3">
                              Uber配達員が専用バッグを集荷するためにご自宅に伺います。<br />
                              到着後、<span className="text-danger">しっかりと密閉した</span>袋をお渡しください。
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper_div rounded-lg">
                          <div className="">
                            <img src="./img/step2-032.png" alt="ste3" className="ItemImage" />
                          </div>
                          <div className="p-3">
                            <div className="h4 mb-3 text-center">
                              クリーニング中
                            </div>
                            <div className="mb-3">
                              クリーニングが仕上がるまで、いましばらくお待ちください
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper_div rounded-lg">
                          <div className="">
                            <img src="./img/step2-03.png" alt="ste3" className="ItemImage" />
                          </div>
                          <div className="p-3">
                            <div className="h4 mb-3 text-center">
                              クリーニング完了
                            </div>
                            <div className="mb-3">
                              クリーニングが完了したら、SMSメールまたは注文履歴のページにてお知らせします。
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper_div rounded-lg">
                          <div className="">
                            <img src="./img/step2-04.png" alt="step4" className="ItemImage" />
                          </div>
                          <div className="p-3">
                            <div className="h4 mb-3 text-center">
                              受け取り
                            </div>
                            <div className="mb-3">
                              今すぐ受け取るボタンを押すと、Uber配達員がお届けに向かいます。<br/>
                              ご自宅でくつろいでお待ちください。
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              )}

              {Number(activeOrder.otype) === 1 && Number(activeOrder.delivery_type)===-1 ? (
                <div className="home_contents">
                  <div className="mb-3">
                    <button className="btn btn-oval btn-outline-secondary form-control" onClick={openTrackingUrl}>配送状況の確認</button>
                  </div>
                </div>
              ) : Number(activeOrder.otype) === 1 && activeOrder.delivery_type === null ? (
                <>
                  {Number(activeOrder.item_id) === -5 ? (
                    <div className="">
                      <div className="home_contents flex_div">
                        <div className="mb-2">
                          <button className="btn btn-oval btn-yellow form-control" onClick={openDeliveryBagModal}>配達を依頼する</button>
                        </div>
                      </div>
                      <div className="mb-6">
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="home_contents flex_div">
                        <div className="mb-2">
                          <button className="btn btn-oval btn-yellow form-control" onClick={openDeliveryCustomerPickupModal}>配達を依頼する</button>
                        </div>
                      </div>
                      <div className="mb-6">
                      </div>
                    </div>
                  )}
                </>
              ) : Number(activeOrder.otype) === 6 ? (
                <div>
                  { inBusinessHours === true ?
                  (
                  <div className="">
                    <div className="home_contents flex_div">
                      <div className="mb-2">
                        <button className="btn btn-oval btn-yellow form-control" onClick={openDeliveryModal}>今すぐ受け取る</button>
                      </div>
                      <div className="mb-2 text-center">
                        <small>受取り可能時間：8:30-20:00（日祝休み）</small>
                      </div>
                    </div>
                  </div>
                  ) : (
                  <div className="">
                    <div className="home_contents flex_div flex_div2">
                      <div className="mb-2">
                        <button className="btn btn-oval btn-gray-dark form-control" onClick={outBusinessHours}>今すぐ受け取る</button>
                      </div>
                      <div className="mb-2 text-center">
                        <small>受取り可能時間：8:30-20:00（日祝休み）</small>
                      </div>
                    </div>
                    <div className="mb-6">
                    </div>
                  </div>
                  )}
                </div>
              ) : Number(activeOrder.otype) === 7 && (
                <div className="home_contents">
                  <div className="mb-3">
                    <button className="btn btn-oval btn-outline-secondary form-control" onClick={openTrackingUrl}>配送状況の確認</button>
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
        <Tabbar menu="home" />

        <Modal>
          <div className="modal rounded">
            <div className="">
              <img src="./img/step2-00.png" alt="modal_image" className="ItemImage" />
            </div>
            <div className="p-4">
              <div className="mb-3">
                <div className="modal_table mb-3">
                  <div className="h6 modal_cell">
                    専用バッグをすぐにご自宅にお届けします。<br/>
                    以下の暗証番号をUber配達員に伝えてください。
                  </div>
                </div>
                <div className=" mb-3 text-center">
                  <div className="h2">
                    {activeOrder !== null && (
                      <div>
                        {activeOrder.pincode}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="text-danger">
                  ※専用バッグに衣類を入れて、しっかりと密封してください。
                </div>
                <div className="text-danger">
                  ※ご依頼品を専用バッグに入れていない場合、かつ密封されていない場合、サービスをお受けできない場合があります。
                </div>
              </div>
              <div className="mb-2">
                <button onClick={closeModal} className="btn btn-oval btn-outline-secondary form-control">確認しました</button>
              </div>
            </div>
          </div>
        </Modal>

        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>

        <DeliveryBagModal>
          <div className="modal rounded p-4">
            <div className="">

              <div className="row">
                <div className="col-12 text-right">
                  <IoMdClose onClick={closeDeliveryBagModal} className="h2 text-gray-dark" />
                </div>
              </div>
              <div className="">
                <div className="h5 text-center mb-2">
                  今からUber配達員がご自宅へ向かいます<br />
                  本当に配達を依頼してよろしいですか？
                </div>
              </div>
              <div>
                <div className="row mb-4">
                  <div className="col-12">
                    <img src="./img/delivery.png" alt="DeliveryImage" className="ItemImage" />
                  </div>
                </div>
                <div className="mb-4">
                  30〜60分前後で到着予定です。
                </div>
                <div className="">
                  <button className="btn btn-oval btn-outline-secondary form-control" onClick={requestDeliveryBag}>配達を依頼する</button>
                </div>
              </div>
            </div>
          </div>
        </DeliveryBagModal>
        <DeliveryCustomerPickupModal>
          <div className="modal rounded p-4">
            <div className="">
              <div className="row">
                <div className="col-12 text-right">
                  <IoMdClose onClick={closeDeliveryCustomerPickupModal} className="h2 text-gray-dark" />
                </div>
              </div>
              <div className="">
                <div className="h5 text-center mb-2">
                  今からUber配達員がご自宅へ向かいます<br />
                  本当に配達を依頼してよろしいですか？
                </div>
              </div>
              <div>
                <div className="row mb-4">
                  <div className="col-12">
                    <img src="./img/delivery.png" alt="DeliveryImage" className="ItemImage" />
                  </div>
                </div>
                <div className="mb-4">
                  10〜60分前後で到着予定です。
                </div>
                <div className="">
                  <button className="btn btn-oval btn-outline-secondary form-control" onClick={requestDeliveryCustomerPickup}>配達を依頼する</button>
                </div>
              </div>
            </div>
          </div>
        </DeliveryCustomerPickupModal>

        <DeliveryModal>
          <div className="modal rounded p-4">
            <div className="">

              <div className="row">
                <div className="col-12 text-right">
                    <IoMdClose onClick={closeDeliveryModal} className="h2 text-gray-dark"/>
                </div>
              </div>
              <div className="">
                <div className="h5 text-center mb-2">
                  今からUber配達員がご自宅へ<br />
                  お届けに向かいます<br />
                  本当によろしいですか？
                </div>
              </div>
              <div>
                <div className="row mb-4">
                  <div className="col-12">
                    <img src="./img/delivery.png" alt="DeliveryImage" className="ItemImage" />
                  </div>
                </div>
                <div className="mb-4">
                  配達依頼後30〜60分前後で到着予定です。Uber配達員が到着したらご依頼品をお受け取りください。
                </div>
                <div className="">
                  <button className="btn btn-oval btn-outline-secondary form-control" onClick={requestDelivery}>今すぐ受け取る</button>
                </div>
              </div>
            </div>
          </div>
        </DeliveryModal>
      </div>
    </div>
  );
}

export default Home;
